import React, { useEffect } from 'react';
import Layout from '../components/App';
import { FullPageSpinner } from '../components/App/FullPageSpinner';
import { useRouter } from 'next/router';

export default function IndexPage() {
  const router = useRouter();

  useEffect(() => {
    router.push('/hello');
  }, [router]);

  return (
    <Layout title={'Welcome To Solv'} auth={false}>
      <FullPageSpinner />
    </Layout>
  );
}
